<template>
    <div>
        <!-- 就診問卷 -->
        <vs-row>
            <vs-col v-for="(item, index) in dataList" :key="index" vs-lg="4" vs-sm="6" vs-xs="12">
                <div class="cd-card-group text-center">
                    <vs-card>
                        <vs-row class="cd-card">
                            <vs-col class="cd-form-group" vs-type="flex" vs-justify="flex-end">
                                <p>{{ item.create_at }}</p>
                            </vs-col>
                            <vs-col>
                                <p class="cd-p-normal">{{ item.title }}</p>
                                <!-- <p class="cd-p-normal">{{item.create_at}}</p> -->
                            </vs-col>
                            <vs-divider />
                            <vs-col vs-type="flex" vs-justify="space-around">
                                <vs-button :id="'question_' + index" size="small" @click="showDetail(item, index)">{{ $t('DetailBtn') }}</vs-button>
                            </vs-col>
                        </vs-row>
                    </vs-card>
                </div>
            </vs-col>
        </vs-row>
        <!-- 沒資料 -->
        <div class="cd-nodata" v-show="dataList.length == 0">
            {{ $t('nodata') }}
        </div>
        <!-- 分頁 -->
        <vs-row v-show="dataList.length != 0">
            <vs-col vs-type="flex" vs-justify="center">
                <vs-pagination :max="windowWidth < 768 ? 5 : 7" :total="Math.ceil(this.questiontotal / 12)" v-model="question_page" @change="getquestionPage(question_page)"></vs-pagination>
            </vs-col>
        </vs-row>
        <!-- 問卷調查 視窗 -->
        <vs-popup fullscreen :title="title" :active.sync="popupQuestion" id="questionpopup">
            <div class="text-center">
                <iframe class="questiondata-iframe" @load="load" width="98%" :src="questionurl" id="questionframe" frameborder="0"></iframe>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import { getQuestionMembers, fetchPtchXrayData, fetchPtchExamData, medicalFileSync, getGHealthReport } from '@/api/user'
import { mapState } from 'vuex'
export default {
    components: {},
    props: {
        userPid: {
            type: String,
            required: true,
        },
        tabName: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapState(['partner_id', 'windowWidth']),
        syncSwitch() {
            return this.$store.getters.syncSwitch
        },
        syncTriggle() {
            return this.$store.getters.syncTriggle
        },
        qid: {
            get() {
                return this.$store.getters.qid
            },
            set(val) {
                this.$store.commit('SET_QUEUE_ID', val)
            },
        },
    },
    mounted() {
        this.fetchData()
    },
    data() {
        return {
            title: this.$t('questionAndAnswer'),
            questionurl: '',
            popupQuestion: false,
            dataList: [],
            question_page: 1,
            question_limit: 10,
            questiontotal: 0,
        }
    },
    watch: {
        popupQuestion(v) {
            if (v == false && this.syncSwitch == true) {
                medicalFileSync({ qid: this.qid, trigger: 'closequestion' })
                // this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'closequestion'})
            }
        },
        syncTriggle(v) {
            if (v == 'closequestion') {
                this.popupQuestion = false
            } else if (v.substring(0, 10) == 'pageChange') {
                var stringValue = v.split('_')
                // 一般影片
                if (stringValue[1] == this.tabName) {
                    try {
                        var intValue = parseInt(stringValue[2])
                    } catch (e) {
                        console.log(e)
                    }
                    this.getquestionPage(intValue, 1)
                }
            }
        },
        question_page(v) {
            this.fetchData()
        },
    },
    methods: {
        // 問卷調查視窗載入執行
        load() {
            this.$vs.loading.close()
            var popupid = document.getElementById('questionpopup')
            var iframeid = document.getElementById('questionframe')
            if (iframeid && popupid) {
                iframeid.height = popupid.clientHeight - 140
            }
        },
        // 開啟 問卷調查視窗
        showDetail(item, index) {
            this.questionurl = item.url
            this.popupQuestion = true
            // 醫療影像同步
            if (this.syncSwitch == true) {
                medicalFileSync({ qid: this.qid, trigger: 'question_' + index })
                // this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'question_'+index})
            }
        },
        // 取資料
        async fetchData() {
            this.$vs.loading()
            if (this.tabName == 'userquestion') {
                await this.getQuestionList()
            } else if (this.tabName == 'radiography') {
                await this.fetchPtchXrayData()
            } else if (this.tabName == 'checkImage') {
                await this.fetchPtchExamData()
            } else if (this.tabName == 'gHealth') {
                await this.getGHealthReport()
            }
        },
        // 取就診問卷List
        async getQuestionList() {
            const payload = {
                partner_id: this.partner_id,
                pid: this.userPid,
                page: this.question_page,
                limit: this.question_limit,
            }
            const res = await getQuestionMembers(payload)
            this.dataList = res.data.data.data
            this.questiontotal = res.data.data.total
            this.$vs.loading.close()
        },
        // 取屏基 病歷-醫療影像-放射影像
        fetchPtchXrayData() {
            fetchPtchXrayData({ spid: this.userPid })
                .then((res) => {
                    if (res.data.status == 'OK') {
                        this.dataList = res.data.items.data
                        this.questiontotal = res.data.items.data.length
                    }
                })
                .then(() => {
                    this.$vs.loading.close()
                })
        },
        // 取屏基 病歷-醫療影像-放射影像
        fetchPtchExamData() {
            fetchPtchExamData({ spid: this.userPid })
                .then((res) => {
                    if (res.data.status == 'OK') {
                        this.dataList = res.data.items.data
                        this.questiontotal = res.data.items.data.length
                    }
                })
                .then(() => {
                    this.$vs.loading.close()
                })
        },
        // 換頁 (設立旗子以防止收到socket後，無限迴圈執行程式)
        getquestionPage(page = 1, flag = 0) {
            // 醫療影像同步
            if (this.syncSwitch == true && flag == 0) {
                medicalFileSync({ qid: this.qid, trigger: 'pageChange_' + this.tabName + '_' + page })
                // this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'pageChange_'+this.tabName+'_'+page})
            }
            this.question_page = page
            window.scrollTo(0, 0)
        },
        // GHealth 取員工健檢報告
        getGHealthReport() {
            const payload = {
                spid: this.userPid,
                partner_id: this.partner_id,
            }
            getGHealthReport(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        this.dataList = res.data.data
                        this.questiontotal = res.data.count
                    }
                })
                .then(() => {
                    this.$vs.loading.close()
                })
        },
    },
}
</script>
