<template>
    <div class="cd-body">
        <list :menu="menu" :total="total" :limit="limit" :isLoading="isLoading" :page.sync="page">
            <template v-slot:header-left>
                <span>{{ $t('crm.pageTitle') }}</span>
            </template>
            <template v-slot:header-right>
                <!-- 篩選 -->
                <div v-if="windowWidth <= 1024">
                    <vs-button class="cd-button-2w-icon rounded" type="flat" icon-pack="feather" icon="icon-filter" @click="switchFilter">{{ $t('apptMgmt.filter') }}</vs-button>
                </div>
            </template>
            <template v-slot:sidebar>
                <div class="mb-base">
                    <vs-input icon-no-border clearable icon="icon-search" icon-pack="feather" class="w-full input-rounded-full" v-model="query" />
                </div>
                <div class="mb-base">
                    <vs-radio class="w-full mb-3" v-model="status" vs-name="status" :vs-value="null" @change="selectFilter">案件狀態（不拘）</vs-radio>
                    <vs-radio class="w-full my-2 ml-4" v-model="status" vs-name="status" :vs-value="0" @change="selectFilter">追蹤中</vs-radio>
                    <vs-radio class="w-full my-2 ml-4" v-model="status" vs-name="status" :vs-value="1" @change="selectFilter">已結案</vs-radio>
                </div>
                <div class="mb-base">
                    <vs-radio class="w-full mb-3" v-model="isAssign" vs-name="isAssign" :vs-value="null" @change="selectFilter">負責人（不拘）</vs-radio>
                    <vs-radio class="w-full my-2 ml-4" v-model="isAssign" vs-name="isAssign" :vs-value="1" @change="selectFilter">我（{{ name }}）</vs-radio>
                    <vs-radio class="w-full my-2 ml-4" v-model="isAssign" vs-name="isAssign" :vs-value="0" @change="selectFilter">未指派</vs-radio>
                </div>
                <div class="mb-base" v-if="crm_types.length > 0">
                    <vs-radio class="w-full mb-3" v-model="crm_type" :vs-value="null" @change="selectFilter">服務類型（不拘）</vs-radio>
                    <vs-radio class="w-full my-2 ml-4" v-for="(type, index) in crm_types" :key="index" v-model="crm_type" :vs-value="type" @change="selectFilter">{{ type }}</vs-radio>
                </div>
                <div class="mb-base" v-if="crm_tags.length > 0">
                    <vs-radio class="w-full mb-3" v-model="crm_tag" :vs-value="null" @change="selectFilter">服務標籤（不拘）</vs-radio>
                    <vs-radio class="w-full my-2 ml-4" v-for="(tag, index) in crm_tags" :key="index" v-model="crm_tag" :vs-value="tag" @change="selectFilter">{{ tag }}</vs-radio>
                </div>
                <div class="mb-base" v-if="unit_list.length > 0">
                    <vs-radio class="w-full mb-3" v-model="eid" :vs-value="null" @change="selectFilter">單位（不拘）</vs-radio>
                    <unit-tree-radio v-for="unit in treeUnits" :key="unit.id" :unit="unit" :depth="1" v-model="eid" @change="selectFilter" />
                </div>
                <!-- <div class="mb-base">
                    <vs-checkbox v-model="includeChildren" @change="selectFilter">含子單位</vs-checkbox>
                </div> -->
            </template>
            <template v-slot:content>
                <div class="mb-4 flex flex-wrap items-center gap-y-2 gap-x-4 text-lg" v-if="query || crm_type || crm_tag || eid || status != null || isAssign != null">
                    <span>篩選條件：</span>
                    <vs-chip class="p-2 text-lg whitespace-no-wrap" closable @click="closeQueryFilter" v-if="query">
                        {{ query }}
                    </vs-chip>
                    <vs-chip class="p-2 text-lg whitespace-no-wrap" closable @click="closeStatusFilter" v-if="status != null">
                        {{ status > 0 ? '已結案' : '追蹤中' }}
                    </vs-chip>
                    <vs-chip class="p-2 text-lg whitespace-no-wrap" closable @click="closeIsAssignFilter" v-if="isAssign != null">
                        {{ isAssign > 0 ? '我（' + name + '）' : '未指派' }}
                    </vs-chip>
                    <vs-chip class="p-2 text-lg whitespace-no-wrap" closable @click="closeTypeFilter" v-if="crm_type">
                        {{ crm_type }}
                    </vs-chip>
                    <vs-chip class="p-2 text-lg whitespace-no-wrap" closable @click="closeTagFilter" v-if="crm_tag">
                        {{ crm_tag }}
                    </vs-chip>
                    <vs-chip class="p-2 text-lg whitespace-no-wrap" closable @click="closeEnterpriseFilter" v-if="eid">
                        {{ ename }}
                        <!-- <span class="text-sm">&nbsp;({{ includeChildren ? '含子單位' : '不含子單位' }})</span> -->
                    </vs-chip>
                </div>
                <div class="mb-4 flex flex-wrap items-center justify-between gap-4 text-lg">
                    <div class="flex flex-wrap items-center gap-4">
                        <span>共 {{ total }} {{ $t('membersMgmt.dataLength') }}</span>
                        <span class="text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="openConfirm('status')">{{ $t('crm.closeAll') }}</span>
                        <span class="text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="openAssign">全部交辦</span>
                    </div>
                    <div class="flex flex-wrap items-center gap-4">
                        <span class="text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="openNewCase">{{ $t('crm.addCase') }}</span>
                    </div>
                </div>
                <div class="mb-8 grid grid-cols-1 md:grid-cols-2 gap-4 items-stretch">
                    <SNItem :data="item" v-for="(item, index) in srData" :key="index"></SNItem>
                </div>
            </template>
        </list>
        <!-- 全部結案 -->
        <vs-popup :title="confirmTitle" :active.sync="activeConfirm" :width="windowWidth > 768 ? '50%' : '80%'" center @close="closeConfirm">
            <!-- <span>{{ $t('crm.selectCase') }}</span>
            <div style="text-align: center" class="flex justify-center">
                <vs-checkbox class="cd-form-group" v-model="finishOwnCase" disabled>{{ $t('crm.assignCase') }}</vs-checkbox>
                <vs-checkbox class="cd-form-group" v-model="finishAllCase">{{ $t('crm.unassignedCase') }}</vs-checkbox>
            </div> -->
            <span>{{ confirmContent }}</span>
            <div style="text-align: center" class="flex flex-wrap gap-4 justify-end">
                <vs-button class="cd-button-2w rounded" @click="closeAllCase" v-if="confirmType == 'status'">{{ $t('crm.close') }}</vs-button>
                <vs-button class="cd-button-2w rounded" @click="assignAllCase" v-if="confirmType == 'did'">{{ $t('crm.close') }}</vs-button>
                <vs-button class="cd-button-2w rounded mr-4" type="flat" @click="activeConfirm = false">{{ $t('popup.cancel') }}</vs-button>
            </div>
        </vs-popup>
        <!-- 全部交辦 -->
        <vs-popup :active.sync="activeAssign" :title="$t('crm.caseAssign')" @close="closeAssign">
            <div class="min-h-lg">
                <div class="w-full flex">
                    <vs-input :placeholder="'搜尋'" v-model="multiAssignQuery" class="cd-form-group flex-grow" />
                </div>
                <div class="px-2 py-4 flex justify-between items-center rounded cursor-pointer hover:bg-grey-light" @click="openConfirm('did', did, name)">
                    <p>{{ name }}{{ $t('crm.myself') }}</p>
                    <div class="flex items-center">
                        <feather-icon icon="PlusIcon" svgClasses="w-4 h-4"></feather-icon>
                    </div>
                </div>
                <div class="px-2 py-4 flex justify-between items-center rounded cursor-pointer hover:bg-grey-light" v-for="(item, index) in getallMedicalNurse" :key="index" @click="openConfirm('did', item.did, item.name)">
                    <p>{{ item.name }}</p>
                    <div class="flex items-center">
                        <feather-icon icon="PlusIcon" svgClasses="w-4 h-4"></feather-icon>
                    </div>
                </div>
                <div class="px-2 py-4" v-if="getallMedicalNurse.length == 0">查無資料</div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import { getCrmConfig, getSNs, closeAllCase, updateMultiSN, fetchAuthList } from '@/api/user'
import SNItem from '@/components/CRM/SNItem.vue'
import list from '@/components/Module/list.vue'
import UnitTreeRadio from '@/components/Module/UnitTreeRadio.vue'
import { debounce } from 'lodash'
import { mapState } from 'vuex'
export default {
    components: {
        SNItem,
        list,
        UnitTreeRadio,
    },
    props: {
        pid: {
            type: Number,
            default: null,
            required: false,
        },
        pname: {
            type: String,
            default: '',
            required: false,
        },
    },
    data() {
        return {
            srData: [],
            query: '',
            total: 0,
            limit: 20,
            page: 1,
            isLoading: false,
            activeConfirm: false,
            finishOwnCase: true,
            finishAllCase: false,
            menu: false,
            eid: null,
            status: 0,
            isAssign: null,
            crm_tags: [],
            crm_tag: null,
            crm_types: [],
            crm_type: null,
            // includeChildren: false,
            activeAssign: false,
            multiAssignQuery: '',
            myMedicalAllNurse: [],
            tempMyMedicalAllNurse: [],
            confirmTitle: '',
            confirmContent: '',
            confirmType: '',
            assignDid: 0,
            assignName: '',
        }
    },
    mounted() {
        // 預設值
        const savedUnit = localStorage.getItem('selectedUnit')
        this.eid = savedUnit ? JSON.parse(savedUnit) : null

        this.createBus()
        this.getConfig()
        this.getSNs()
        this.$store.dispatch('fetchSRQuantity', { partnerid: this.partner_id })
        this.fetchAuthList()
    },
    watch: {
        crmNewCase(n) {
            if (n == true) this.getSNs()
        },
        query: 'selectFilter',
        status: 'selectFilter',
        isAssign: 'selectFilter',
        crm_type: 'selectFilter',
        crm_tag: 'selectFilter',
        eid(v) {
            localStorage.setItem('selectedUnit', JSON.stringify(v))
            this.selectFilter()
        },
        multiAssignQuery(n) {
            if (n != '') {
                var temp = _.filter(this.myMedicalAllNurse, function (o) {
                    return o.name.includes(n)
                })
                this.tempMyMedicalAllNurse = _.cloneDeep(temp)
            } else {
                this.tempMyMedicalAllNurse = _.cloneDeep(this.myMedicalAllNurse)
            }
        },
    },
    computed: {
        ...mapState(['unit_list', 'partner_id', 'did', 'name', 'crmNewCase']),
        windowWidth() {
            return this.$store.state.windowWidth
        },
        ename() {
            const data = _.filter(this.unit_list, { id: this.eid })
            return data.length > 0 ? data[0].name : ''
        },
        treeUnits() {
            return this.$common.buildUnitTree()
        },
        filter() {
            const payload = {
                partner_id: this.partner_id,
                page: this.page,
                limit: this.limit,
                withDetails: 1,
                withPatient: 1,
                withDoctor: 1,
                withCreator: 1,
                includeChildren: 0,
            }
            if (this.eid) payload.eid = this.eid
            if (this.status != null) payload.status = this.status
            if (this.isAssign != null) payload.isAssign = this.isAssign
            if (this.crm_tag) payload.tags = [this.crm_tag]
            if (this.crm_type) payload.crm_type = [this.crm_type]
            if (this.$route.path == '/crm') payload.did = this.did
            if (this.pid) payload.pid = this.pid
            if (this.query) payload.query = this.query
            return payload
        },
        getallMedicalNurse() {
            if (!this.tempMyMedicalAllNurse) return []
            // 最多顯示50筆資料
            return this.tempMyMedicalAllNurse.slice(0, 50)
        },
    },
    methods: {
        // 取服務案件設定檔
        async getConfig() {
            const payload = { partner_id: this.partner_id }
            const res = await getCrmConfig(payload)
            this.crm_tags = res.data.data.crm_tags ? res.data.data.crm_tags : []
            this.crm_types = res.data.data.crm_types ? res.data.data.crm_types : []
        },
        // 取服務記錄
        getSNs: debounce(async function (event) {
            this.isLoading = true
            const payload = this.filter
            const res = await getSNs(payload)
            this.srData = res.data.data.data
            this.total = res.data.data.total
            this.$store.commit('SET_CRM_NEW_CASE', false)
            this.isLoading = false
            window.scrollTo(0, 0)
        }, 300),
        // 全部結案
        async closeAllCase() {
            this.isLoading = true
            const filter = this.filter
            const update = {
                status: 1,
            }
            const payload = {
                filter: filter,
                update: update,
            }
            const res = await updateMultiSN(payload)
            console.log('updateMultiSN', res.data.data)
            this.$store.dispatch('notify', { color: 'grey', title: this.$t('crm.close_success') })
            this.selectFilter()
            this.closeConfirm()
        },
        // 全部交辦
        async assignAllCase() {
            this.isLoading = true
            const filter = this.filter
            const update = {
                did: this.assignDid,
            }
            const payload = {
                filter: filter,
                update: update,
            }
            const res = await updateMultiSN(payload)
            console.log('updateMultiSN', res.data.data)
            this.$store.dispatch('notify', { color: 'grey', title: '交辦成功' })
            this.selectFilter()
            this.closeConfirm()
        },
        // 開啟新增客服案件視窗
        openNewCase() {
            const payload = {}
            if (this.pid) {
                payload.pid = this.pid
                payload.pname = this.pname
            }
            this.$bus.$emit('sn', payload)
        },
        // 全部結案警告視窗
        openConfirm(type, did = 0, name = '') {
            this.confirmType = type
            if (type == 'status') {
                this.confirmTitle = '全部結案'
                this.confirmContent = '您確定要將目前的' + this.total + '筆案件全部結案？'
            } else if (type == 'did') {
                this.assignDid = did
                this.assignName = name
                this.confirmTitle = '全部交辦'
                this.confirmContent = '您確定要將目前的' + this.total + '筆案件全部交辦給' + this.assignName + '？'
            }
            this.closeAssign()
            this.activeConfirm = true
        },
        // 全部結案警告視窗
        closeConfirm() {
            this.activeConfirm = false
        },
        // 全部交辦警告視窗
        openAssign() {
            this.activeAssign = true
        },
        // 全部交辦警告視窗
        closeAssign() {
            this.activeAssign = false
        },
        // 全部結案
        // closeAllCase() {
        //     var note = this.$t('crm.detailFinish', { dname: this.name })
        //     const payload = {
        //         partner_id: this.partner_id,
        //         all_case: this.finishAllCase,
        //         notes: note,
        //     }
        //     closeAllCase(payload).then((res) => {
        //         if (res.data.status == 'OK') {
        //             this.$store.dispatch('notify', { color: 'grey', title: this.$t('crm.close_success') })
        //             this.getSNs()
        //             this.$store.dispatch('fetchSRQuantity', { partnerid: this.partner_id })
        //         }
        //         this.activeConfirm = false
        //     })
        // },
        // 開關篩選sidebar (手機版)
        switchFilter() {
            this.menu = !this.menu
        },
        selectFilter() {
            this.page = 1
            this.getSNs()
        },
        closeQueryFilter() {
            this.query = ''
            this.selectFilter()
        },
        closeTypeFilter() {
            this.crm_type = null
            this.selectFilter()
        },
        closeTagFilter() {
            this.crm_tag = null
            this.selectFilter()
        },
        closeEnterpriseFilter() {
            this.eid = null
            this.selectFilter()
        },
        closeStatusFilter() {
            this.status = null
            this.selectFilter()
        },
        closeIsAssignFilter() {
            this.isAssign = null
            this.selectFilter()
        },
        createBus() {
            this.$bus.$on('changePage', (page) => {
                this.page = page
                this.getSNs()
            })
        },
        // 取醫師可授權的醫事人員名單
        fetchAuthList() {
            fetchAuthList(this.partner_id).then((res) => {
                this.myMedicalAllNurse = _.cloneDeep(res.data.data)
                this.tempMyMedicalAllNurse = _.cloneDeep(res.data.data)
            })
        },
    },
    beforeDestroy() {
        this.$bus.$off('changePage')
    },
}
</script>
