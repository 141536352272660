<template>
    <div class="p-4 sm:p-8" v-if="info">
        <!-- 會員資料 -->
        <div class="mb-12">
            <div class="mb-4 flex flex-wrap whitespace-no-wrap gap-x-32 gap-y-4">
                <div class="flex flex-col whitespace-no-wrap gap-4">
                    <div class="flex gap-4">
                        <span class="w-24 cd-text-muted font-semibold">{{ $t('PName3') }}</span>
                        <span>{{ info.name }}</span>
                    </div>
                    <div class="flex gap-4">
                        <span class="w-24 cd-text-muted font-semibold">性別</span>
                        <span>{{ info.sex ? $common.getGender(info.sex) + '性' : '' }}</span>
                    </div>
                    <div class="flex gap-4">
                        <span class="w-24 cd-text-muted font-semibold">年齡</span>
                        <span>{{ info.birthday ? $common.getAge(info.birthday) + '歲' : '' }}</span>
                    </div>
                    <div class="flex gap-4">
                        <span class="w-24 cd-text-muted font-semibold">{{ $t('Birthday') }}</span>
                        <span>{{ info.birthday }}</span>
                    </div>
                </div>
                <div class="flex flex-col whitespace-no-wrap gap-4">
                    <div class="flex gap-4" v-if="partner_id == 32">
                        <span class="w-24 cd-text-muted font-semibold">{{ $t('SciNo') }}</span>
                        <span>{{ info.account }}</span>
                    </div>
                    <div class="flex gap-4" v-else>
                        <span class="w-24 cd-text-muted font-semibold">{{ $t('ID') }}</span>
                        <span>{{ info.id }}</span>
                    </div>
                    <div class="flex gap-4">
                        <span class="w-24 cd-text-muted font-semibold">{{ $t('CardID') }}</span>
                        <span>{{ info.insurance_id }}</span>
                    </div>
                    <div class="flex gap-4" v-if="info.native">
                        <span class="w-24 font-semibold">原住民身份</span>
                    </div>
                    <div class="flex gap-4">
                        <span class="w-24 cd-text-muted font-semibold">{{ $t('ContactTxt') }}</span>
                        <div class="flex items-center text-primary cursor-pointer hover:font-semibold" @click="showUrgentDig = true">
                            <!-- <feather-icon icon="InfoIcon" svgClasses="h-4 w-4" class="mr-1" /> -->
                            <span>個人與緊急聯絡人資訊</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 所屬單位 -->
            <div>
                <div class="flex gap-4">
                    <span class="w-24 cd-text-muted font-semibold">所屬單位</span>
                    <div class="flex flex-wrap gap-2" v-if="units.length > 0">
                        <span class="cd-button-group whitespace-no-wrap" v-for="unit in units" :key="unit.id">
                            <vx-tooltip :text="$t('partnerWhichMemberBelong')">
                                <feather-icon svgClasses="h-3 w-3" icon="FlagIcon"></feather-icon>
                                {{ unit.name }}
                            </vx-tooltip>
                        </span>
                    </div>
                </div>
                <vs-dropdown class="text-primary cursor-pointer" vs-trigger-click v-if="(doctortype == 2 || doctortype == 4) && addEnterpriseAuth && enterpriseList.length > 0">
                    <p class="cd-button-group">
                        <feather-icon svgClasses="h-3 w-3" icon="PlusIcon"></feather-icon>
                        {{ $t('joinEnterprise') }}
                    </p>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="(item, index) in enterpriseList" :key="index" @click="patientAddEnterprise(item.id)">
                            {{ item.name }}
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
                <vs-checkbox v-model="seniorMemberStatus" v-if="(doctortype == 2 || doctortype == 4) && seniorMemberAuth" @click="updateSeniorMemberStatus">
                    {{ $t('advancedMember') }}
                </vs-checkbox>
            </div>
        </div>

        <!-- 個人病史、家族慢性病史、家族癌症病史、過敏事項、生活型態 -->
        <div class="flex flex-col gap-2">
            <MedHistoryItem :history="history" :pid="parseInt(pid)" v-for="history in medHistory" :key="history.code" @update="getData"></MedHistoryItem>
        </div>

        <!-- 聯絡資訊視窗 -->
        <vs-popup width="100vw" :title="$t('ContactTxt')" :active.sync="showUrgentDig">
            <div class="mb-12 flex flex-col whitespace-no-wrap gap-4">
                <span class="text-lg">{{ $t('Profile') }}</span>
                <div class="flex gap-4">
                    <span class="w-28 cd-text-muted font-semibold">{{ $t('PName3') }}</span>
                    <span>{{ info.name }}</span>
                </div>
                <div class="flex gap-4">
                    <span class="w-28 cd-text-muted font-semibold">{{ $t('accountPhone') }}</span>
                    <span>{{ info.country_code }} {{ info.mobile }}</span>
                </div>
                <div class="flex gap-4">
                    <span class="w-28 cd-text-muted font-semibold">{{ $t('Email') }}</span>
                    <span>{{ info.email }}</span>
                </div>
                <div class="flex gap-4">
                    <span class="w-28 cd-text-muted font-semibold">{{ $t('BankAddressTxt') }}</span>
                    <span class="whitespace-normal">{{ info.address }}{{ info.address }}</span>
                </div>
            </div>
            <div class="flex flex-col whitespace-no-wrap gap-4">
                <span class="text-lg">{{ $t('EmergencyCI') }}</span>
                <div class="flex gap-4">
                    <span class="w-28 cd-text-muted font-semibold">{{ $t('EmergencyRelation') }}</span>
                    <span>{{ info.urgent_relationship }}</span>
                </div>
                <div class="flex gap-4">
                    <span class="w-28 cd-text-muted font-semibold">{{ $t('EmergencyContact') }}</span>
                    <span>{{ info.urgent_contact }}</span>
                </div>
                <div class="flex gap-4">
                    <span class="w-28 cd-text-muted font-semibold">{{ $t('EmergencyContactNum') }}</span>
                    <span>{{ info.urgent_country_code }} {{ info.urgent_phone }}</span>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import FeatherIcon from '@/components/FeatherIcon.vue'
import MedHistoryItem from '@/components/Emr/Base/MedHistoryItem.vue'
import { getEmrData, checkEmrBaseAuthThenGetList, hisUpdateMemberSeniorMember, hisPatientAddPartnerAndEnterprise } from '@/api/user'

export default {
    components: { FeatherIcon, MedHistoryItem },
    inject: ['pid'],
    data() {
        return {
            info: null,
            units: [],
            medHistory: [],
            showUrgentDig: false,
            activeMedHistory: false,
            disease_type: '',
            selectType: 1,
            title: '',
            listData: [],
            listDataOther: {},
            showOther: false,
            othertext: '',
            addEnterpriseAuth: false,
            seniorMemberAuth: false,
            seniorMemberStatus: false,
            enterpriseList: [],
        }
    },
    computed: {
        ...mapState(['partner_id', 'windowWidth', 'doctortype']),
        lang() {
            return this.$i18n.locale
        },
    },
    mounted() {
        this.getData()
        if (this.doctortype === 2 || this.doctortype === 4) {
            this.checkAuthThenGetList()
        }
    },
    methods: {
        async getData() {
            try {
                const payload = { partner_id: this.partner_id, pid: this.pid }
                const res = await getEmrData(payload)
                const { info, units, medHistory } = res.data.data
                this.info = info
                this.units = units
                this.medHistory = medHistory
            } catch (error) {
                console.error('取病歷資料失敗', error)
            }
        },
        checkAuthThenGetList() {
            checkEmrBaseAuthThenGetList({ partnerid: this.partner_id, spid: this.pid }).then((res) => {
                if (res.data.status === 'OK') {
                    this.seniorMemberAuth = res.data.data.seniorMemberAuth
                    this.addEnterpriseAuth = res.data.data.addAuth
                    if (this.seniorMemberAuth === 1 && res.data.data.seniorMemberStatus) {
                        this.seniorMemberStatus = res.data.data.seniorMemberStatus[0].is_senior_member
                    }
                    if (res.data.data.enterpriseList) {
                        this.enterpriseList = res.data.data.enterpriseList
                    }
                }
            })
        },
        updateSeniorMemberStatus() {
            hisUpdateMemberSeniorMember({ partner_id: this.partner_id, pid: this.pid })
        },
        async patientAddEnterprise(eid) {
            await hisPatientAddPartnerAndEnterprise({ partner_id: this.partner_id, eid, pid: this.pid })
            this.checkAuthThenGetList()
        },
    },
    beforeDestroy() {
        this.activeMedHistory = false
    },
}
</script>
