<template>
    <div>
        <!-- 醫療影像同步 -->
        <div v-if="calling">
            <label class="con-s mr-3">{{ $t('photoSync') }}</label>
            <vs-switch v-model="syncSwitch" @click="switchsync" />
        </div>
        <vs-tabs>
            <div v-for="(item, index) in config" :key="index">
                <!-- {{ item }} -->
                <vs-tab :label="geti18n(item[1].i18n)" :id="item[0]" :ref="item[0]" :name="item[0]" @click="syncTriggleF(item[0])" v-if="tabAuth(item[1].role) && item[1].show">
                    <general :userPid="userPid" :tabName="item[0]" :i18n="geti18n(item[1].i18n)" v-if="item[1].type == 'img' || item[1].type == 'pdf'"></general>
                    <!-- 患部照片 & 超音波照片 -->
                    <!-- <general-photo :userPid="userPid" :tabName="item[0]" :i18n="geti18n(item[1].i18n)" v-if="item[1].type=='img'"></general-photo> -->
                    <!-- 健檢報告(PDF) & 心電圖 -->
                    <!-- <general-report :userPid="userPid" :tabName="item[0]" :i18n="geti18n(item[1].i18n)" v-if="item[1].type=='pdf'"></general-report> -->
                    <!-- DICOM -->
                    <display-dicom type="2" :userPid="userPid" :tabName="item[0]" v-if="item[1].type == 'dicom'"></display-dicom>
                    <!-- 心電心音 -->
                    <display-dicom type="12" :userPid="userPid" :tabName="item[0]" v-if="item[1].type == 'heartSound'"></display-dicom>
                    <!-- 一般影片 -->
                    <general-video :userPid="userPid" :consulttype="11" v-if="item[1].type == 'video'"></general-video>
                    <!-- 就診問卷 & 檢查影像(屏基) & 放射影像(屏基) -->
                    <question-data :userPid="userPid" :tabName="item[0]" v-if="item[1].type == 'iframe'"></question-data>
                    <!-- 五官鏡 -->
                    <vs-tabs v-if="item[1].type == 'tabs'">
                        <vs-tab :label="geti18n(data[1].i18n)" :id="data[0]" :ref="data[0]" :name="data[0]" @click.stop="syncTriggleF(data[0])" v-for="(data, index) in config2" :key="index" v-show="data[1].show">
                            <!-- 工研院_AI -->
                            <display-dicom type="13" :userPid="userPid" :tabName="data[0]" v-if="data[1].type == 'funduscopy'"></display-dicom>
                            <!-- 眼底鏡 & 耳道 & 鼻腔 & 咽喉 & 口腔 & 皮膚 -->
                            <general :userPid="userPid" :tabName="data[0]" :i18n="geti18n(data[1].i18n)" v-if="data[1].type == 'img' || data[1].type == 'pdf'"></general>
                            <!-- <general-photo :userPid="userPid" :tabName="data[0]" :i18n="geti18n(data[1].i18n)" v-if="data[1].type=='img'"></general-photo> -->
                        </vs-tab>
                    </vs-tabs>
                </vs-tab>
            </div>
        </vs-tabs>
    </div>
</template>

<script>
import { medicalFileSync } from '@/api/user'
import 'chartjs-plugin-zoom'
import DisplayDicom from '@/components/displayimg/dicom.vue'
import General from './general.vue'
import GeneralPhoto from './generalphoto.vue'
import GeneralReport from './generalreport.vue'
import GeneralVideo from './generalvideo.vue'
import QuestionData from './questiondata.vue'
export default {
    components: {
        DisplayDicom,
        GeneralPhoto,
        GeneralReport,
        General,
        QuestionData,
        GeneralVideo,
    },
    props: {
        userPid: {
            type: String,
            required: true,
        },
    },
    watch: {
        // 醫療影像同步
        syncTriggle(v) {
            if (v != 'dicomData' && v != 'closeimg' && v != 'closedicom' && v != 'closedialog' && v != 'closevideo' && v != 'closequestion' && v.substring(0, 10) != 'pageChange') {
                // 設立旗子以防止收到socket後，無限迴圈執行程式(開關圖檔視窗時不執行)
                if (v.substring(0, 8) != 'userimg_' && v.substring(0, 7) != 'report_' && v.substring(0, 13) != 'viewDicomBtn_' && v.substring(0, 20) != 'ectrocarDiogram_' && v.substring(0, 6) != 'video_' && v.substring(0, 9) != 'question_') {
                    this.socketFlag = 1
                    console.log('syncTriggle flag 1', v)
                }
                console.log('syncTriggle flag 0', v)
                document.getElementById(v).click()
            }
        },
    },
    computed: {
        doctortype() {
            return this.$store.state.doctortype
        },
        device() {
            return this.$store.getters.device
        },
        syncSwitch: {
            get() {
                return this.$store.getters.syncSwitch
            },
            set(val) {
                if (this.roomid != 0) {
                    // this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'dicomData'})
                    this.$store.commit('SET_SYNC_SWITCH', val)
                    this.$store.commit('SET_SYNC_STATUS', val)
                }
            },
        },
        roomid() {
            return this.$store.getters.roomid
        },
        syncTriggle: {
            get() {
                return this.$store.getters.syncTriggle
            },
            set(val) {
                this.$store.commit('SET_SYNC_TRIGGLE', val)
            },
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        calling() {
            return this.$store.getters.calling
        },
        qid: {
            get() {
                return this.$store.getters.qid
            },
            set(val) {
                this.$store.commit('SET_QUEUE_ID', val)
            },
        },
        config() {
            // 第一層tabs
            let data = JSON.parse(localStorage.getItem('his_config')).emr.dicomData.contents
            _.pull(data, _.find(data, { show: false }))
            let map = new Map()
            for (let k in data) {
                map.set(k, data[k])
            }
            let ArrayObj = Array.from(map)
            ArrayObj.sort((a, b) => {
                return a[1]['order'] - b[1]['order']
            })
            return ArrayObj
        },
        config2() {
            // 第二層tabs
            let data = JSON.parse(localStorage.getItem('his_config')).emr.dicomData.contents.facial.contents
            _.pull(data, _.find(data, { show: false }))
            let map = new Map()
            for (let k in data) {
                map.set(k, data[k])
            }
            let ArrayObj = Array.from(map)
            ArrayObj.sort((a, b) => {
                return a[1]['order'] - b[1]['order']
            })
            return ArrayObj
        },
    },
    data() {
        return {
            photopage: 1,
            getphotototal: null,
            datalist: [],
            srcList: [],
            reportDatalist: [],
            reportempty: false,
            photoempty: false,
            consultdoctorbutton: null,
            generaltemplatebutton: null,
            generalphototemplatebutton: null,
            consulttype: null,
            socketFlag: 0,
        }
    },
    methods: {
        // 醫療影像同步 點擊每個tab傳送socket
        syncTriggleF(v) {
            console.log('發送socket，點擊頁籤', v)
            var _self = this
            _self.$store.commit('SET_SYNC_SELECTEDTAB', v)
            // 醫療影像
            if (v == 'dicomData') {
                document.getElementById(v).click()
            }
            // 點所有tab都會推socket
            if (_self.syncSwitch == true && this.socketFlag == 0) {
                // _self.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: v})
                medicalFileSync({ qid: this.qid, trigger: v })
            } else {
                this.socketFlag = 0
            }
        },
        // 醫療影像同步 switch trigger
        switchsync() {
            if (this.roomid != 0) {
                console.log('發送socket，開啟同步')
                this.$store.commit('SET_SYNC_SWITCH', this.syncSwitch)
                // this.$socket.client.emit('post',{tag: 'emr', type: 'event', trigger: 'dicomData'})
                medicalFileSync({ qid: this.qid, trigger: 'dicomData' })
            } else {
                this.$vs.dialog({
                    color: 'danger',
                    title: this.$t('NoConnection'),
                    text: this.$t('ConnectFirst'),
                })
            }
        },
        // 判斷醫療影像各頁籤的權限
        tabAuth(role) {
            var _self = this
            if (role == 'all') {
                return true
            } else {
                let temp = _self.doctortype
                let rs = false
                role.forEach(function (v) {
                    if (v == temp) {
                        rs = true
                    }
                })
                return rs
            }
        },
        // 判斷語系
        geti18n(items) {
            if (this.$i18n.locale == 'tw') {
                return items.tw
            } else if (this.$i18n.locale == 'cn') {
                return items.cn
            } else if (this.$i18n.locale == 'en') {
                return items.en
            }
        },
    },
    destroyed() {
        this.$store.commit('SET_SYNC_SWITCH', false)
    },
}
</script>
