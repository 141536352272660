<template>
    <div>
        <textarea class="w-full cd-emr-textarea" :rows="rows" v-model="text"></textarea>
        <div class="flex flex-wrap gap-2 items-center">
            <div class="p-2 border rounded max-w-32 truncate hover:font-semibold cursor-pointer" style="border: #999 1px solid" v-for="(word, index) in opinionWords" :key="index" v-if="index < 3" @click="input(word)">
                <span class="text-primary">{{ word.word }}</span>
            </div>
            <span class="text-primary hover:font-semibold cursor-pointer" v-if="opinionWords.length > 3" @click="openWords">更多</span>
        </div>
        <vs-popup :active.sync="activeWords" :title="'更多常用字句'" style="z-index: 51002" @close="closeWords">
            <div>
                <div class="hover:bg-grey-light p-4 flex justify-between gap-4 rounded cursor-pointer overflow-hidden" v-for="(word, index) in opinionWords" :key="index" @click="input(word)">
                    <div class="truncate">
                        <span>{{ word.word }}</span>
                    </div>
                    <div>
                        <span class="text-primary">套用</span>
                    </div>
                </div>
            </div>
        </vs-popup>
    </div>
</template>
<script>
import { updateWord } from '@/api/user'
// import { mapGetters } from 'vuex'
export default {
    props: {
        value: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'wd',
            required: false,
        },
        rows: {
            type: Number,
            default: 6,
            required: false,
        },
    },
    data() {
        return {
            activeWords: false,
        }
    },
    computed: {
        // ...mapGetters(['words', 'wordsByCode']),
        opinionWords() {
            const found = this.$store.state.words.find((item) => item.code === this.type)
            return found ? found.words : []
            // return this.wordsByCode(this.type)
        },
        text: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
    methods: {
        input(word) {
            this.text += word.word
            this.updateWord(word)
            this.closeWords()
        },
        openWords() {
            this.activeWords = true
        },
        closeWords() {
            this.activeWords = false
        },
        // 更新常用字句
        async updateWord(word) {
            try {
                const payload = {
                    word_id: word.id,
                    num: word.num + 1,
                }
                await updateWord(payload)
                this.$store.dispatch('getHisConfig')
            } catch (error) {
                console.error('更新常用字句失敗', error)
            }
        },
    },
}
</script>
