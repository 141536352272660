<template>
    <div class="px-4 sm:px-8" v-if="data">
        <!-- 個案管理 #241059 / 立案日期 / 最後更新日期 -->
        <div class="flex flex-wrap items-center gap-x-4 gap-y-1" v-if="!quickEdit">
            <span class="text-sm">個案管理 #{{ data.id }}</span>
            <span class="text-sm text-gray-500 whitespace-no-wrap">{{ $common.formatDate(data.created_at) }} 立案</span>
            <span class="text-sm text-gray-500 whitespace-no-wrap">{{ $common.formatDate(data.updated_at) }} 最後更新</span>
        </div>
        <!-- 王小明 三高個案 / 3天後追蹤 / 點點點 -->
        <div class="mt-1 mb-6 flex items-start justify-between" v-if="!quickEdit">
            <div class="flex flex-wrap items-center gap-2 text-2xl">
                <span>{{ data.patient.name }}</span>
                <span>{{ data.title }}</span>
            </div>
            <div class="flex items-center gap-4">
                <vs-dropdown ref="myDropdown" class="cursor-pointer hover:font-semibold" :class="assignFlag ? 'disabled text-grey' : 'text-dark'" :disabled="assignFlag ? true : false" vs-custom-content vs-trigger-click @click.native="trackDays = 0">
                    <div class="flex items-center whitespace-no-wrap text-primary" v-if="data.status == 1">
                        <span>{{ $t('crm.finished') }}</span>
                        <feather-icon icon="ChevronDownIcon" svg-classes="w-5 h-5" />
                    </div>
                    <div class="text-danger flex items-center whitespace-no-wrap" v-else>
                        <span class="ml-1 cursor-pointer">{{ getTrackDays(data.tracked_at) }}</span>
                        <span class="cursor-pointer">{{ $t('crm.track') }}</span>
                        <feather-icon icon="ChevronDownIcon" svg-classes="w-5 h-5" />
                    </div>
                    <vs-dropdown-menu>
                        <ul class="whitespace-no-wrap">
                            <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" @click="updateStatus(0)" v-if="data.status == 0">
                                <span>{{ $t('crm.finish') }}</span>
                            </li>
                            <vs-divider class="my-2" v-if="data.status == 0" />
                            <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" @click="updateStatus(1)">
                                <span>{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 1 }) }}</span>
                            </li>
                            <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" @click="updateStatus(3)">
                                <span>{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 3 }) }}</span>
                            </li>
                            <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" @click="updateStatus(5)">
                                <span>{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 5 }) }}</span>
                            </li>
                            <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" @click="updateStatus(7)">
                                <span>{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 7 }) }}</span>
                            </li>
                            <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" @click="updateStatus(30)">
                                <span>{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 30 }) }}</span>
                            </li>
                            <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light">
                                <span>{{ $t('crm.tracking') }}</span>
                                <input type="number" class="ml-2 w-12" @input="updateStatus(parseInt(trackDays), false)" v-model="trackDays" />
                                <span>{{ $t('crm.days', { days: '' }) }}</span>
                            </li>
                        </ul>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </div>
        </div>
        <!-- 負責人 / 交辦 / 案件指派單位 -->
        <div class="mb-8" v-if="!quickEdit">
            <div class="flex items-center space-x-4 whitespace-no-wrap">
                <div>
                    <span>負責人</span>
                    <span>：{{ data.doctor ? data.doctor.dname : $t('crm.unassigned') }}</span>
                </div>
                <div class="ml-2 text-primary cursor-pointer hover:font-semibold" @click="cancelAssign" v-if="assignFlag">
                    {{ $t('popup.cancel') }}
                </div>
                <div class="ml-2 flex items-center text-primary cursor-pointer hover:font-semibold" @click="openAssignPopup" v-else>
                    <feather-icon icon="CornerUpRightIcon" class="mr-1" svgClasses="w-4 h-4" />
                    <span>{{ $t('crm.assign') }}</span>
                </div>
            </div>
            <div v-if="data.enterprise">
                <span>案件指派單位</span>
                <span>：{{ data.enterprise.name }}</span>
            </div>
        </div>
        <!-- 服務標籤 -->
        <div class="mb-8" v-if="crm_tags_config.length > 0">
            <div class="mb-2 flex items-center gap-4">
                <span class="text-lg">服務標籤</span>
                <div class="flex items-center">
                    <feather-icon icon="RefreshCwIcon" class="mr-1 text-success" svgClasses="w-4 h-4" v-if="tagsFeeback == true" />
                    <p class="text-success" v-if="tagsFeeback == true">
                        {{ $t('emr.appt.saving') }}
                    </p>
                    <feather-icon icon="CheckIcon" class="mr-1 text-success" svgClasses="w-4 h-4" v-if="tagsFeeback == false" />
                    <p class="text-success" v-if="tagsFeeback == false">
                        {{ $t('emr.appt.saved') }}
                    </p>
                </div>
            </div>
            <div class="flex items-center flex-wrap gap-x-4 gap-y-2 whitespace-no-wrap">
                <vs-checkbox v-for="(tag, index) in crm_tags_config" :key="index" v-model="data.tags" :vs-value="tag" @change="updateTags">{{ tag }}</vs-checkbox>
            </div>
        </div>
        <!-- 案件類型 -->
        <div class="mb-8" v-if="crm_types_config.length > 0">
            <div class="mb-2 flex items-center gap-4">
                <span class="text-lg">案件類型</span>
                <div class="flex items-center">
                    <feather-icon icon="RefreshCwIcon" class="mr-1 text-success" svgClasses="w-4 h-4" v-if="typeFeeback == true" />
                    <p class="text-success" v-if="typeFeeback == true">
                        {{ $t('emr.appt.saving') }}
                    </p>
                    <feather-icon icon="CheckIcon" class="mr-1 text-success" svgClasses="w-4 h-4" v-if="typeFeeback == false" />
                    <p class="text-success" v-if="typeFeeback == false">
                        {{ $t('emr.appt.saved') }}
                    </p>
                </div>
            </div>
            <div class="flex items-center flex-wrap gap-x-4 gap-y-2 whitespace-no-wrap">
                <vs-checkbox v-for="(type, index) in crm_types_config" :key="index" v-model="data.crm_type" :vs-value="type" @change="updateTypes">{{ type }}</vs-checkbox>
            </div>
        </div>
        <!-- 案件註記 -->
        <div class="mb-8">
            <div class="mb-2 flex items-center whitespace-no-wrap space-x-4">
                <div class="text-lg">案件註記</div>
                <div>
                    <importSetWord :dataType="'wd/all'" @importMsg="importMsg($event)"></importSetWord>
                </div>
                <div class="flex items-center text-success cursor-pointer hover:font-semibold" @click="openSymptom">
                    <feather-icon icon="SunIcon" class="mr-1" svgClasses="w-4 h-4" />
                    <span>AI關懷建議</span>
                </div>
            </div>
            <div>
                <div class="mb-4 flex w-full">
                    <textarea v-model="note" rows="2" class="flex-grow border rounded-l px-4 py-2 text-base resize-none" @keydown.enter.exact.prevent="createSND(note)" />
                    <vs-button class="cd-button-2w border rounded-r" @click="createSND(note)">{{ $t('crm.addNotes') }}</vs-button>
                </div>
                <div class="flex flex-col gap-4">
                    <div class="p-4 bg-grey-light rounded" v-for="(detail, index) in data.details.slice().reverse()" :key="index">
                        <div class="flex justify-between items-end whitespace-no-wrap space-x-2">
                            <span class="text-sm cd-text-muted">{{ detail.creator ? detail.creator.dname : '系統建立' }} {{ getTimeAgo(detail.created_at.substr(0, 19)) }}</span>
                            <span class="text-primary cursor-pointer hover:font-semibold" @click="$common.copyTextToClipboard(detail.note)">複製</span>
                        </div>
                        <div class="flex mr-2">
                            <!-- <div class="mr-2">●</div> -->
                            <p class="whitespace-pre-wrap">{{ detail.note }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 勾選症狀 -->
        <vs-popup :title="'勾選個案症狀，供AI產出更佳的關懷建議'" :active.sync="activeSymptom" style="z-index: 51002">
            <div>
                <!-- 1. 常見感冒症狀 -->
                <div class="mb-8">
                    <span class="font-semibold mb-2">常見感冒症狀</span>
                    <div class="flex items-center flex-wrap gap-x-4 gap-y-2 whitespace-no-wrap">
                        <vs-checkbox v-model="symptoms" :vs-value="'常見感冒症狀-發燒'">發燒</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'常見感冒症狀-流鼻水'">流鼻水</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'常見感冒症狀-鼻塞'">鼻塞</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'常見感冒症狀-咳嗽'">咳嗽</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'常見感冒症狀-喉嚨痛'">喉嚨痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'常見感冒症狀-感到疲倦'">感到疲倦</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'常見感冒症狀-四肢無力'">四肢無力</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'常見感冒症狀-失眠'">失眠</vs-checkbox>
                    </div>
                </div>

                <!-- 2. 神經與心臟相關 -->
                <div class="mb-8">
                    <span class="font-semibold mb-2">神經與心臟相關</span>
                    <div class="flex items-center flex-wrap gap-x-4 gap-y-2 whitespace-no-wrap">
                        <vs-checkbox v-model="symptoms" :vs-value="'神經與心臟相關-頭昏'">頭昏</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'神經與心臟相關-頭痛'">頭痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'神經與心臟相關-冒冷汗'">冒冷汗</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'神經與心臟相關-心悸'">心悸</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'神經與心臟相關-胸悶'">胸悶</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'神經與心臟相關-胸痛'">胸痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'神經與心臟相關-呼吸困難'">呼吸困難</vs-checkbox>
                    </div>
                </div>

                <!-- 3. 腸胃道症狀 -->
                <div class="mb-8">
                    <span class="font-semibold mb-2">腸胃道症狀</span>
                    <div class="flex items-center flex-wrap gap-x-4 gap-y-2 whitespace-no-wrap">
                        <vs-checkbox v-model="symptoms" :vs-value="'腸胃道症狀-食慾不振'">食慾不振</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'腸胃道症狀-腹痛'">腹痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'腸胃道症狀-脹氣'">脹氣</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'腸胃道症狀-噁心'">噁心</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'腸胃道症狀-嘔吐'">嘔吐</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'腸胃道症狀-腹瀉'">腹瀉</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'腸胃道症狀-便祕'">便祕</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'腸胃道症狀-排便異常'">排便異常</vs-checkbox>
                    </div>
                </div>

                <!-- 4. 皮膚症狀 -->
                <div class="mb-8">
                    <span class="font-semibold mb-2">皮膚症狀</span>
                    <div class="flex items-center flex-wrap gap-x-4 gap-y-2 whitespace-no-wrap">
                        <vs-checkbox v-model="symptoms" :vs-value="'皮膚症狀-紅疹'">紅疹</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'皮膚症狀-搔癢'">搔癢</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'皮膚症狀-疼痛'">疼痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'皮膚症狀-斑塊'">斑塊</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'皮膚症狀-膿皰'">膿皰</vs-checkbox>
                    </div>
                </div>

                <!-- 5. 骨骼肌肉症狀 -->
                <div class="mb-8">
                    <span class="font-semibold mb-2">骨骼肌肉症狀</span>
                    <div class="flex items-center flex-wrap gap-x-4 gap-y-2 whitespace-no-wrap">
                        <vs-checkbox v-model="symptoms" :vs-value="'骨骼肌肉症狀-全身酸痛'">全身酸痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'骨骼肌肉症狀-肩頸酸痛'">肩頸酸痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'骨骼肌肉症狀-關節疼痛'">關節疼痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'骨骼肌肉症狀-骨頭酸痛'">骨頭酸痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'骨骼肌肉症狀-肌肉酸痛'">肌肉酸痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'骨骼肌肉症狀-行動困難'">行動困難</vs-checkbox>
                    </div>
                </div>

                <!-- 6. 體重變化 -->
                <div class="mb-8">
                    <span class="font-semibold mb-2">體重變化</span>
                    <div class="flex items-center flex-wrap gap-x-4 gap-y-2 whitespace-no-wrap">
                        <vs-checkbox v-model="symptoms" :vs-value="'體重變化-體重快速降低'">體重快速降低</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'體重變化-體重快速增加'">體重快速增加</vs-checkbox>
                    </div>
                </div>

                <!-- 個人病史、家族慢性病史、家族癌症病史、過敏事項、生活型態 -->
                <div class="mb-8 flex flex-col gap-2">
                    <MedHistoryItem :history="history" :pid="parseInt(data.pid)" v-for="history in medHistory" :key="history.code" @update="getEmrData"></MedHistoryItem>
                </div>

                <div class="p-4 flex justify-center gap-8">
                    <vs-button class="rounded" type="border" @click="closeSymptom">取消</vs-button>
                    <vs-button class="rounded" @click="aiSuggestion">產出建議</vs-button>
                </div>
            </div>
        </vs-popup>
    </div>
</template>
<script>
import { getCrmConfig, getSNs, updateSN, createSND, snAiSuggestion, getEmrData } from '@/api/user'
import { debounce } from 'lodash'
import { mapState } from 'vuex'
import importSetWord from '@/components/importSetWord'
import MedHistoryItem from '@/components/Emr/Base/MedHistoryItem.vue'
export default {
    components: {
        importSetWord,
        MedHistoryItem,
    },
    props: {
        sn_id: {
            type: Number,
            default: 0,
            require: true,
        },
        quickEdit: {
            type: Boolean,
            default: false,
            require: false,
        },
    },
    data() {
        return {
            // sn_id: 0,
            data: null,
            trackDays: 0,
            crm_tags_config: [],
            crm_types_config: [],
            note: '',
            tagsFeeback: null,
            typeFeeback: null,
            assignFlag: false,
            suggestion: '',
            activeSymptom: false,
            symptoms: [],
            medHistory: [],
        }
    },
    watch: {
        tagsFeeback(v) {
            if (v == false) {
                setTimeout(() => {
                    this.tagsFeeback = null
                }, 5000)
            }
        },
        typeFeeback(v) {
            if (v == false) {
                setTimeout(() => {
                    this.typeFeeback = null
                }, 5000)
            }
        },
    },
    computed: {
        ...mapState(['partner_id', 'did', 'name']),
    },
    mounted() {
        this.createBus()
        this.getConfig()
        this.getSNs()
    },
    methods: {
        // 取服務案件設定檔
        async getConfig() {
            const payload = { partner_id: this.partner_id }
            const res = await getCrmConfig(payload)
            this.crm_tags_config = res.data.data.crm_tags ? res.data.data.crm_tags : []
            this.crm_types_config = res.data.data.crm_types ? res.data.data.crm_types : []
        },
        // 取服務記錄
        getSNs: debounce(async function () {
            try {
                const payload = {
                    sn_id: this.sn_id,
                    withDetails: 1,
                    withPatient: 1,
                    withDoctor: 1,
                    withCreator: 1,
                    withEnterprise: 1,
                }
                const res = await getSNs(payload)
                this.data = res.data.data.data[0]
                if (!this.data.tags) this.data.tags = []
                if (!this.data.crm_type) this.data.crm_type = []
                const data = {
                    key: 'details',
                    value: this.data.details,
                }
                this.$emit('update', data)
            } catch (error) {
                console.error('取服務記錄失敗', error)
            }
        }, 300),
        // 更新案件狀態
        async updateStatus(days, autoCloseDropdown = true) {
            try {
                const payload = {
                    sn_id: this.sn_id,
                    status: days == 0 ? 1 : 0,
                }
                if (days != 0) {
                    let date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + days)
                    date = this.$moment(date).format('YYYY-MM-DD')
                    payload.tracked_at = date
                }
                await updateSN(payload)
                this.data.status = payload.status
                this.data.tracked_at = payload.tracked_at
                if (autoCloseDropdown) this.$refs.myDropdown.$children[0].dropdownVisible = false
            } catch (error) {
                console.error('交辦案件失敗', error)
            }
        },
        // 更新案件標籤
        async updateTags() {
            try {
                this.tagsFeeback = true
                const payload = {
                    sn_id: this.sn_id,
                    tags: this.data.tags,
                }
                await updateSN(payload)
                this.tagsFeeback = false
                const data = {
                    key: 'tags',
                    value: this.data.tags,
                }
                this.$emit('update', data)
            } catch (error) {
                console.error('交辦案件失敗', error)
            }
        },
        // 更新案件類型
        async updateTypes() {
            try {
                this.typeFeeback = true
                const payload = {
                    sn_id: this.sn_id,
                    crm_type: this.data.crm_type,
                }
                await updateSN(payload)
                this.typeFeeback = false
            } catch (error) {
                console.error('交辦案件失敗', error)
            }
        },
        // 新增註記
        async createSND(note) {
            try {
                const payload = {
                    sn_id: this.sn_id,
                    note: note,
                }
                await createSND(payload)
                await this.getSNs()
                this.note = ''
            } catch (error) {
                console.error('新增註記失敗', error)
            }
        },
        // 匯入常用字句
        importMsg(msg) {
            // 判斷最後一個字元是不是換行符號
            let lastWord = this.note.split('')[this.note.split('').length - 1]
            if (lastWord != '\n' && lastWord != undefined) {
                this.note += '\n'
            }
            this.note += msg
        },
        // 開啟交辦案件視窗
        openAssignPopup() {
            const payload = { sn_id: this.data.id }
            this.$bus.$emit('assign', payload)
        },
        // 取消交辦
        async cancelAssign() {
            const payload = {
                sn_id: this.data.id,
                did: this.tempDid,
            }
            await updateSN(payload)

            const note = this.$t('crm.cancelAssignMsg', { from_name: this.name })
            await this.createSND(note)

            this.data.did = this.tempDid
            this.data.doctor.dname = this.tempName
            this.data.doctor = {
                did: this.tempDid,
                dname: this.tempName,
            }
            this.assignFlag = false
        },
        // 取追蹤天數
        getTrackDays(date) {
            if (!date) return ''
            var date = new Date(date)
            var now = new Date()
            date = this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
            now = this.$moment(now).format('YYYY-MM-DD HH:mm:ss')
            var endDate = date.replace(/-/g, '/')
            var startDate = now.replace(/-/g, '/')
            var diff = 0
            diff = new Date(endDate) - new Date(startDate)
            var days = Math.floor(diff / (24 * 3600 * 1000)) + 1
            if (days == 1) {
                return ' ' + this.$t('crm.tomorrow')
            } else if (days == 0) {
                return ' ' + this.$t('crm.today')
            } else if (days > 0) {
                return ' ' + this.$t('crm.days', { days: days })
            } else if (days < 0) {
                return ' ' + this.$t('crm.daysAgo', { days: -days })
            } else {
                return ''
            }
        },
        // 取時間多久前
        getTimeAgo(timestamp) {
            const now = Date.now()
            const then = Date.parse(timestamp.replace(/-/g, '/'))
            const seconds = Math.floor((now - then) / 1000)
            const minutes = Math.floor(seconds / 60)
            const hours = Math.floor(minutes / 60)
            const days = Math.floor(hours / 24)
            const months = Math.floor(days / 30)
            const years = Math.floor(months / 12)

            if (seconds < 60) {
                return this.$t('crm.sec', { num: seconds })
            } else if (minutes < 60) {
                return this.$t('crm.min', { num: minutes })
            } else if (hours < 24) {
                return this.$t('crm.hour', { num: hours })
            } else if (days < 30) {
                return this.$t('crm.day', { num: days })
            } else if (months < 12) {
                return this.$t('crm.month', { num: months })
            } else {
                return this.$t('crm.year', { num: years })
            }
        },
        // 建立 event bus
        createBus() {
            this.$bus.$on('assignSn', (item) => {
                if (item.sn_id != this.data.id) return
                this.tempDid = this.data.did
                this.tempName = this.data.doctor ? this.data.doctor.dname : ''
                this.data.did = item.did
                this.data.doctor = {
                    did: item.did,
                    dname: item.name,
                }
                if (this.tempDid > 0) this.assignFlag = true
            })
            this.$bus.$on('applySuggestion', (data) => {
                this.note = this.suggestion
            })
        },
        openSymptom() {
            this.getEmrData()
            this.activeSymptom = true
        },
        closeSymptom() {
            this.activeSymptom = false
        },
        async getEmrData() {
            const payload = { partner_id: this.partner_id, pid: this.data.pid }
            const res = await getEmrData(payload)
            const { medHistory } = res.data.data
            this.medHistory = medHistory
            console.log('medHistory', this.medHistory)
        },
        // 生成AI關懷建議
        async aiSuggestion() {
            try {
                this.$vs.loading()
                const chosenSymptoms = `個案目前的症狀包含: ${this.symptoms.join('、')}`
                const payload = { sn_id: this.data.id, prompt: chosenSymptoms }
                const res = await snAiSuggestion(payload)
                this.suggestion = res.data.data
                this.$vs.loading.close()
                this.closeSymptom()
                this.openSuggestion()
            } catch (error) {
                this.$vs.loading.close()
                console.error(error)
            }
        },
        // 打開AI結果視窗
        openSuggestion() {
            const payload = {
                desc: 'AI 作為提升效率的輔助工具，使用者仍需確認其正確性並適當調整。',
                suggestion: this.suggestion,
            }
            this.$bus.$emit('aiSuggestion', payload)
        },
    },
    beforeDestroy() {
        this.$bus.$off('assignSn')
    },
}
</script>
