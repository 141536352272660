<template>
    <div class="flex flex-col p-4 border rounded shadow" style="border: #999 1px solid" :style="data.status == 0 ? 'background-color: #fee;' : 'background-color: white;'">
        <div class="h-full">
            <!-- 第一行: #241059 / 最後更新日期 -->
            <div class="flex flex-wrap whitespace-no-wrap items-center justify-between gap-x-4 gap-y-1">
                <div class="flex flex-wrap gap-x-4 gap-y-1 text-sm">
                    <span>#{{ data.id }}</span>
                    <span>{{ $common.formatDate(data.updated_at) }} 最後更新</span>
                </div>
                <vs-dropdown ref="myDropdown" class="cursor-pointer hover:font-semibold" :class="assignFlag ? 'disabled text-grey' : 'text-dark'" :disabled="assignFlag ? true : false" vs-custom-content vs-trigger-click @click.native="trackDays = 0">
                    <div class="flex items-center whitespace-no-wrap text-primary" v-if="data.status == 1">
                        <span>{{ $t('crm.finished') }}</span>
                        <feather-icon icon="ChevronDownIcon" svg-classes="w-5 h-5" />
                    </div>
                    <div class="text-primary flex items-center whitespace-no-wrap" :class="!data.tracked_at || $common.formatDate(data.tracked_at) > $common.getCurrentDate() ? 'text-primary' : 'text-danger'" v-if="data.status == 0">
                        <span class="cursor-pointer">{{ getTrackDays(data.tracked_at) }}</span>
                        <span class="cursor-pointer" v-if="data.tracked_at">追蹤</span>
                        <span class="cursor-pointer" v-else>追蹤中</span>
                        <feather-icon icon="ChevronDownIcon" svg-classes="w-5 h-5" />
                    </div>
                    <vs-dropdown-menu>
                        <ul class="whitespace-no-wrap">
                            <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" @click="updateStatus(0)" v-if="data.status == 0">
                                <span>{{ $t('crm.finish') }}</span>
                            </li>
                            <vs-divider class="my-2" v-if="data.status == 0" />
                            <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" @click="updateStatus(1)">
                                <span>{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 1 }) }}</span>
                            </li>
                            <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" @click="updateStatus(3)">
                                <span>{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 3 }) }}</span>
                            </li>
                            <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" @click="updateStatus(5)">
                                <span>{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 5 }) }}</span>
                            </li>
                            <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" @click="updateStatus(7)">
                                <span>{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 7 }) }}</span>
                            </li>
                            <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light" @click="updateStatus(30)">
                                <span>{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 30 }) }}</span>
                            </li>
                            <li class="flex p-2 rounded cursor-pointer hover:bg-grey-light">
                                <span>{{ $t('crm.tracking') }}</span>
                                <input type="number" class="ml-2 w-12" @input="updateStatus(parseInt(trackDays), false)" v-model="trackDays" />
                                <span>{{ $t('crm.days', { days: '' }) }}</span>
                            </li>
                        </ul>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </div>

            <!-- 第二行: 王小明 / 負責人:吳五 -->
            <div class="overflow-hidden text-xl">
                <div class="flex items-center space-x-4 whitespace-no-wrap">
                    <span class="text-primary cursor-pointer hover:font-semibold" @click="goToMr">{{ data.patient.name }}</span>
                    <span>{{ data.title }}</span>
                </div>
            </div>

            <!-- 第三行: 標題 -->
            <div class="mt-1 flex flex-wrap gap-x-4 gap-y-2">
                <span>負責人: {{ data.doctor ? data.doctor.dname : getUnitName(data.eid) + ' (' + $t('crm.unassigned') + ')' }}</span>
                <div class="flex items-center text-primary cursor-pointer hover:font-semibold" @click="cancelAssign" v-if="assignFlag">
                    <feather-icon icon="CornerUpRightIcon" class="mr-1" svgClasses="w-4 h-4" />
                    <div>{{ $t('popup.cancel') }}</div>
                </div>
                <div class="flex items-center text-primary cursor-pointer hover:font-semibold" @click="openAssignPopup" v-else>
                    <feather-icon icon="CornerUpRightIcon" class="mr-1" svgClasses="w-4 h-4" />
                    <div>{{ $t('crm.assign') }}</div>
                </div>
            </div>

            <!-- 第四行: 服務標籤 -->
            <div class="mt-1 flex items-center flex-wrap gap-x-4 gap-y-2" v-if="data.tags && data.tags.length > 0">
                <div class="flex items-center whitespace-no-wrap" v-for="(tagitem, index) in data.tags" :key="index">
                    <feather-icon icon="TagIcon" svg-classes="w-4 h-4" />
                    <span class="ml-1">{{ tagitem }}</span>
                </div>
            </div>
            <!-- 第五行: 註記 -->
            <div class="mt-1 flex flex-wrap items-start justify-between gap-x-4 gap-y-2">
                <div class="truncate" v-if="data.details.length > 0">
                    <span>●&nbsp;&nbsp;{{ data.details[data.details.length - 1].note }}</span>
                </div>
            </div>
        </div>

        <vs-divider />

        <!-- CTA: 3天後追蹤 / 交辦 / 快速編輯 / 點點點 -->
        <div class="flex flex-wrap justify-evenly gap-x-8 gap-y-2">
            <div class="flex items-center text-primary cursor-pointer hover:font-semibold" @click="openEdit">
                <feather-icon icon="Edit2Icon" class="mr-1" svgClasses="w-4 h-4" />
                <div>標註</div>
            </div>
            <div class="flex items-center text-primary cursor-pointer hover:font-semibold" @click="showChatPopup(data.patient.mid, data.patient.name)">
                <feather-icon icon="MessageCircleIcon" class="mr-1" svgClasses="w-4 h-4" />
                <div>{{ $t('crm.sendMsg') }}</div>
            </div>
            <div class="flex items-center text-success cursor-pointer hover:font-semibold" @click="openSymptom">
                <feather-icon icon="SunIcon" class="mr-1" svgClasses="w-4 h-4" />
                <div>AI關懷建議</div>
            </div>
            <vs-dropdown class="cursor-pointer hover:font-semibold" vs-custom-content vs-trigger-click>
                <div class="flex items-center text-primary">
                    <span>更多</span>
                    <feather-icon icon="MoreVerticalIcon" svg-classes="w-5 h-5" />
                </div>
                <vs-dropdown-menu>
                    <ul style="min-width: 12rem">
                        <li class="flex p-2 rounded text-primary cursor-pointer hover:bg-grey-light" @click="goToMr">
                            <span>檢視/編輯案件</span>
                        </li>
                        <li class="flex p-2 rounded text-primary cursor-pointer hover:bg-grey-light" @click="openNewCasePopup">
                            <span>{{ $t('crm.addCase') }}</span>
                        </li>
                        <li class="flex p-2 rounded text-primary cursor-pointer hover:bg-grey-light" @click="openAssignPopup">
                            <span>交辦</span>
                        </li>
                        <vs-divider class="my-1" v-if="data.patient.member.mobile || data.patient.care_giver" />
                        <li class="flex p-2 rounded text-primary cursor-pointer hover:bg-grey-light" @click="showCallPopup">
                            <span>視訊通話</span>
                        </li>
                        <!-- <li class="flex p-2 rounded text-primary cursor-pointer hover:bg-grey-light" @click="showChatPopup(data.patient.mid, data.patient.name)">
                            <span>{{ $t('crm.sendMsg') }}</span>
                        </li> -->
                        <li class="flex p-2 rounded text-primary cursor-pointer hover:bg-grey-light" v-if="data.patient.member.mobile">
                            <a :href="getPhone(data.patient.member.mobile)">電話 {{ data.patient.member.mobile }}</a>
                        </li>
                        <!-- @click.stop="showContactCaregiverPopup(data.patient.care_giver)" -->
                        <li class="flex p-2 rounded text-primary cursor-pointer hover:bg-grey-light" v-if="data.patient.care_giver">
                            <span>
                                照護者 {{ data.patient.care_giver.name }}({{ data.patient.care_giver.relationship }})
                                <a :href="getPhone(data.patient.care_giver.mobile)">{{ data.patient.care_giver.mobile }}</a>
                            </span>
                        </li>
                        <vs-divider class="my-1" />
                        <li class="flex p-2 rounded text-primary cursor-pointer hover:bg-grey-light" @click.stop="showApptPopup(0, data.pid, data.patient.name)">
                            <span>{{ $t('crm.agent') }}</span>
                        </li>
                        <li class="flex p-2 rounded text-primary cursor-pointer hover:bg-grey-light" @click.stop="showApptPopup(1, data.pid, data.patient.name)">
                            <span>{{ $t('crm.special') }}</span>
                        </li>
                        <li class="flex p-2 rounded text-primary cursor-pointer hover:bg-grey-light" @click.stop="showApptPopup(2, data.pid, data.patient.name)">
                            <span>{{ $t('apptMgmt.consultation') }}</span>
                        </li>
                        <vs-divider class="my-1" v-if="allow_shop" />
                        <li class="flex p-2 rounded text-primary cursor-pointer hover:bg-grey-light" @click="openProductPopup" v-if="allow_shop">
                            <span>{{ $t('emr.appt.Recommend') }}</span>
                        </li>
                    </ul>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>

        <!-- 快速編輯 -->
        <vs-popup :title="'快速編輯'" :active.sync="activeEdit" :width="windowWidth > 768 ? '50%' : '80%'" center style="z-index: 51001">
            <sn-detail :sn_id="parseInt(data.id)" :quickEdit="true" @update="updateDetail" v-if="activeEdit"></sn-detail>
        </vs-popup>

        <!-- 勾選症狀 -->
        <vs-popup :title="'勾選個案症狀，供AI產出更佳的關懷建議'" :active.sync="activeSymptom" style="z-index: 51002">
            <div>
                <!-- 1. 常見感冒症狀 -->
                <div class="mb-8">
                    <span class="font-semibold mb-2">常見感冒症狀</span>
                    <div class="flex items-center flex-wrap gap-x-4 gap-y-2 whitespace-no-wrap">
                        <vs-checkbox v-model="symptoms" :vs-value="'常見感冒症狀-發燒'">發燒</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'常見感冒症狀-流鼻水'">流鼻水</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'常見感冒症狀-鼻塞'">鼻塞</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'常見感冒症狀-咳嗽'">咳嗽</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'常見感冒症狀-喉嚨痛'">喉嚨痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'常見感冒症狀-感到疲倦'">感到疲倦</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'常見感冒症狀-四肢無力'">四肢無力</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'常見感冒症狀-失眠'">失眠</vs-checkbox>
                    </div>
                </div>

                <!-- 2. 神經與心臟相關 -->
                <div class="mb-8">
                    <span class="font-semibold mb-2">神經與心臟相關</span>
                    <div class="flex items-center flex-wrap gap-x-4 gap-y-2 whitespace-no-wrap">
                        <vs-checkbox v-model="symptoms" :vs-value="'神經與心臟相關-頭昏'">頭昏</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'神經與心臟相關-頭痛'">頭痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'神經與心臟相關-冒冷汗'">冒冷汗</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'神經與心臟相關-心悸'">心悸</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'神經與心臟相關-胸悶'">胸悶</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'神經與心臟相關-胸痛'">胸痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'神經與心臟相關-呼吸困難'">呼吸困難</vs-checkbox>
                    </div>
                </div>

                <!-- 3. 腸胃道症狀 -->
                <div class="mb-8">
                    <span class="font-semibold mb-2">腸胃道症狀</span>
                    <div class="flex items-center flex-wrap gap-x-4 gap-y-2 whitespace-no-wrap">
                        <vs-checkbox v-model="symptoms" :vs-value="'腸胃道症狀-食慾不振'">食慾不振</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'腸胃道症狀-腹痛'">腹痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'腸胃道症狀-脹氣'">脹氣</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'腸胃道症狀-噁心'">噁心</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'腸胃道症狀-嘔吐'">嘔吐</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'腸胃道症狀-腹瀉'">腹瀉</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'腸胃道症狀-便祕'">便祕</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'腸胃道症狀-排便異常'">排便異常</vs-checkbox>
                    </div>
                </div>

                <!-- 4. 皮膚症狀 -->
                <div class="mb-8">
                    <span class="font-semibold mb-2">皮膚症狀</span>
                    <div class="flex items-center flex-wrap gap-x-4 gap-y-2 whitespace-no-wrap">
                        <vs-checkbox v-model="symptoms" :vs-value="'皮膚症狀-紅疹'">紅疹</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'皮膚症狀-搔癢'">搔癢</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'皮膚症狀-疼痛'">疼痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'皮膚症狀-斑塊'">斑塊</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'皮膚症狀-膿皰'">膿皰</vs-checkbox>
                    </div>
                </div>

                <!-- 5. 骨骼肌肉症狀 -->
                <div class="mb-8">
                    <span class="font-semibold mb-2">骨骼肌肉症狀</span>
                    <div class="flex items-center flex-wrap gap-x-4 gap-y-2 whitespace-no-wrap">
                        <vs-checkbox v-model="symptoms" :vs-value="'骨骼肌肉症狀-全身酸痛'">全身酸痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'骨骼肌肉症狀-肩頸酸痛'">肩頸酸痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'骨骼肌肉症狀-關節疼痛'">關節疼痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'骨骼肌肉症狀-骨頭酸痛'">骨頭酸痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'骨骼肌肉症狀-肌肉酸痛'">肌肉酸痛</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'骨骼肌肉症狀-行動困難'">行動困難</vs-checkbox>
                    </div>
                </div>

                <!-- 6. 體重變化 -->
                <div class="mb-8">
                    <span class="font-semibold mb-2">體重變化</span>
                    <div class="flex items-center flex-wrap gap-x-4 gap-y-2 whitespace-no-wrap">
                        <vs-checkbox v-model="symptoms" :vs-value="'體重變化-體重快速降低'">體重快速降低</vs-checkbox>
                        <vs-checkbox v-model="symptoms" :vs-value="'體重變化-體重快速增加'">體重快速增加</vs-checkbox>
                    </div>
                </div>

                <!-- 個人病史、家族慢性病史、家族癌症病史、過敏事項、生活型態 -->
                <div class="mb-8 flex flex-col gap-2">
                    <MedHistoryItem :history="history" :pid="parseInt(data.pid)" v-for="history in medHistory" :key="history.code" @update="getEmrData"></MedHistoryItem>
                </div>

                <div class="p-4 flex justify-center gap-8">
                    <vs-button class="rounded" type="border" @click="closeSymptom">取消</vs-button>
                    <vs-button class="rounded" @click="aiSuggestion">產出建議</vs-button>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import { updateSN, createSND, snAiSuggestion, getEmrData } from '@/api/user'
import { mapState } from 'vuex'
import snDetail from '@/components/CRM/SNDetail.vue'
import MedHistoryItem from '@/components/Emr/Base/MedHistoryItem.vue'
export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        snDetail,
        MedHistoryItem,
    },
    data() {
        return {
            trackDays: 0,
            assignFlag: false,
            activeEdit: false,
            suggestion: '',
            activeSymptom: false,
            symptoms: [],
            medHistory: [],
        }
    },
    mounted() {
        this.createBus()
    },
    computed: {
        ...mapState(['unit_list', 'name', 'allow_shop', 'windowWidth', 'partner_id']),
    },
    methods: {
        // 取追蹤天數
        getTrackDays(date) {
            if (!date) return ''
            var date = new Date(date)
            var now = new Date()
            date = this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
            now = this.$moment(now).format('YYYY-MM-DD HH:mm:ss')
            var endDate = date.replace(/-/g, '/')
            var startDate = now.replace(/-/g, '/')
            var diff = 0
            diff = new Date(endDate) - new Date(startDate)
            var days = Math.floor(diff / (24 * 3600 * 1000)) + 1
            if (days == 1) {
                return ' ' + this.$t('crm.tomorrow')
            } else if (days == 0) {
                return ' ' + this.$t('crm.today')
            } else if (days > 0) {
                return ' ' + this.$t('crm.days', { days: days })
            } else if (days < 0) {
                return ' ' + this.$t('crm.daysAgo', { days: -days })
            } else {
                return ''
            }
        },
        // 更新案件狀態
        async updateStatus(days, autoCloseDropdown = true) {
            try {
                const payload = {
                    sn_id: this.data.id,
                    status: days == 0 ? 1 : 0,
                }
                if (days != 0) {
                    let date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + days)
                    date = this.$moment(date).format('YYYY-MM-DD')
                    payload.tracked_at = date
                }
                await updateSN(payload)
                this.data.status = payload.status
                this.data.tracked_at = payload.tracked_at
                if (autoCloseDropdown) this.$refs.myDropdown.$children[0].dropdownVisible = false
            } catch (error) {
                console.error('更新案件狀態失敗', error)
            }
        },
        updateDetail(param) {
            this.data[param.key] = param.value
        },
        // 開啟該病人的病歷
        goToMr() {
            const route = _.replace(_.lowerCase(_.split(this.$route.fullPath, '/', 2)[1]), ' ', '')
            if (route != 'emr2') {
                const routerPayload = {
                    path: '/emr2/' + this.data.pid + '/0?index=crm_detail&id=' + this.data.id,
                }
                this.$router.push(routerPayload)
            } else {
                this.$bus.$emit('toSnd', this.data.id)
            }
        },
        // 開啟交辦案件視窗
        openAssignPopup() {
            const payload = { sn_id: this.data.id }
            this.$bus.$emit('assign', payload)
        },
        // 取消交辦
        async cancelAssign() {
            const payload = {
                sn_id: this.data.id,
                did: this.tempDid,
            }
            await updateSN(payload)

            const note = this.$t('crm.cancelAssignMsg', { from_name: this.name })
            await this.createSND(note)

            this.data.did = this.tempDid
            this.data.doctor.dname = this.tempName
            this.data.doctor = {
                did: this.tempDid,
                dname: this.tempName,
            }
            this.assignFlag = false
        },
        // 新增註記
        async createSND(note) {
            const payload = {
                sn_id: this.data.id,
                note: note,
            }
            await createSND(payload)
        },
        //開啟推薦商品視窗
        openProductPopup() {
            const payload = {
                sn_id: this.data.id,
                pid: this.data.pid,
                pname: this.data.patient.name,
            }
            this.$bus.$emit('product', payload)
        },
        // 直接打電話 轉成href格式
        getPhone(number) {
            number = number.slice(2, 10)
            number = 'tel:+886-9' + number
            return number
        },
        // 取單位名字
        getUnitName(eid) {
            const unit = _.find(this.unit_list, { id: eid })
            return unit ? unit.name : ''
        },
        // 開啟 聯繫照護者視窗
        // showContactCaregiverPopup(data){
        //     this.familyData = data
        //     this.contactCaregiverActive = true
        // },
        // 關閉 聯繫照護者視窗
        // closeContactCaregiverPopup(){
        //     this.contactCaregiverActive = false
        // },
        // 開啟 傳送訊息視窗
        showChatPopup(mid, name) {
            const data = {
                smid: mid,
                sname: name,
            }
            this.$bus.$emit('chat', data)
        },
        // 開啟 掛號視窗
        showApptPopup(index, pid = null, pname = null) {
            const data = {
                apptMode: index,
                patient: {
                    pid: parseInt(pid),
                    name: pname,
                },
            }
            this.$bus.$emit('appt', data)
        },
        // 開啟 通話視窗
        showCallPopup() {
            const payload = {
                title: '會員通話',
                pid: this.data.pid,
                sn_id: this.data.id,
                noContact: true,
                noEnterprise: true,
            }
            this.$bus.$emit('callList', payload)
        },
        // 開啟新增客服案件視窗
        openNewCasePopup() {
            const payload = {
                pid: this.data.pid,
                pname: this.data.patient.name,
            }
            this.$bus.$emit('sn', payload)
        },
        openEdit() {
            this.activeEdit = true
        },
        closeEdit() {
            this.activeEdit = false
        },
        // 建立 event bus
        createBus() {
            this.$bus.$on('assignSn', (item) => {
                if (item.sn_id != this.data.id) return
                this.tempDid = this.data.did
                this.tempName = this.data.doctor ? this.data.doctor.dname : ''
                this.data.did = item.did
                this.data.doctor = {
                    did: item.did,
                    dname: item.name,
                }
                if (this.tempDid > 0) this.assignFlag = true
            })
        },
        openSymptom() {
            this.getEmrData()
            this.activeSymptom = true
        },
        closeSymptom() {
            this.activeSymptom = false
        },
        async getEmrData() {
            const payload = { partner_id: this.partner_id, pid: this.data.pid }
            const res = await getEmrData(payload)
            const { medHistory } = res.data.data
            this.medHistory = medHistory
        },
        // 生成AI關懷建議
        async aiSuggestion() {
            try {
                this.$vs.loading()
                const chosenSymptoms = `個案目前的症狀包含: ${this.symptoms.join('、')}`
                const payload = { sn_id: this.data.id, prompt: chosenSymptoms }
                const res = await snAiSuggestion(payload)
                this.suggestion = res.data.data
                this.$vs.loading.close()
                this.closeSymptom()
                this.openSuggestion()
            } catch (error) {
                this.$vs.loading.close()
                console.error(error)
            }
        },
        // 打開AI結果視窗
        openSuggestion() {
            const payload = {
                desc: 'AI 作為提升效率的輔助工具，使用者仍需確認其正確性並適當調整。',
                suggestion: this.suggestion,
                showApply: false,
            }
            this.$bus.$emit('aiSuggestion', payload)
        },
    },
    beforeDestroy() {
        this.$bus.$off('assignSn')
    },
}
</script>
