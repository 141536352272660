<template>
    <div class="-mx-2 p-2 flex items-center justify-between gap-4 rounded cursor-pointer hover:bg-grey-light" @click="openMedHistory">
        <div class="flex items-center whitespace-no-wrap">
            <p class="w-32 cd-text-muted font-semibold">{{ $common.getLangDB(history) }}</p>
            <div class="gap-x-2 flex flex-wrap">
                <span v-for="item in history.data" :key="item.mh_item_id">
                    {{ item.mh_other_value ? item.mh_other_value : $common.getLangDB(item) }}
                </span>
                <span v-if="history.data.length == 0">無</span>
            </div>
        </div>
        <feather-icon class="text-primary" svgClasses="h-4 w-4" icon="Edit2Icon"></feather-icon>

        <!-- 病史編輯彈窗 -->
        <vs-popup :active.sync="activeMedHistory" :title="$common.getLangDB(history)" style="z-index: 51003" @close="closeMedHistory">
            <div class="mb-4 grid grid-cols-1 sm:grid-cols-3 gap-4 whitespace-no-wrap">
                <vs-checkbox v-model="mh_item_ids" :vs-value="item.mh_item_id" v-for="(item, index) in items" :key="index">
                    {{ $common.getLangDB(item) }}
                </vs-checkbox>
                <vs-checkbox v-model="mh_item_ids" :vs-value="0">其他</vs-checkbox>
            </div>
            <vs-textarea class="mb-4 cd-emr-textarea border-black" v-show="mh_item_ids.includes(0)" v-model="othertext" />
            <div class="flex justify-center gap-4">
                <vs-button class="rounded" @click="updateMedHistory">{{ $t('Update') }}</vs-button>
                <vs-button class="rounded" type="border" color="dark" @click="closeMedHistory">{{ $t('Cancel') }}</vs-button>
            </div>
        </vs-popup>
    </div>
</template>
<script>
import { getMedHistoryItems, updatePatientMedHistory } from '@/api/user'
export default {
    props: {
        history: {
            type: Object,
            default: null,
            require: true,
        },
        pid: {
            type: Number,
            default: 0,
            require: true,
        },
    },
    data() {
        return {
            activeMedHistory: false,
            othertext: '',
            items: [],
            mh_item_ids: [],
        }
    },
    methods: {
        async openMedHistory() {
            try {
                const res = await getMedHistoryItems()
                this.items = res.data.data[this.history.code]
                this.mh_item_ids = _.map(this.history.data, 'mh_item_id')
                if (this.mh_item_ids.includes(0)) this.othertext = _.get(_.find(this.history.data, { mh_item_id: 0 }), 'mh_other_value', '')
                this.activeMedHistory = true
            } catch (error) {
                console.error('開啟更新視窗失敗', error)
            }
        },
        closeMedHistory() {
            this.othertext = ''
            this.activeMedHistory = false
        },
        async updateMedHistory() {
            try {
                const payload = {
                    pid: this.pid,
                    mh_type: this.history.code,
                    mh_item_ids: _.filter(this.mh_item_ids, (item) => item !== 0),
                    other: this.othertext,
                }
                const res = await updatePatientMedHistory(payload)
                this.$emit('update')
                this.closeMedHistory()
            } catch (error) {
                console.error('更新病歷資料失敗', error)
            }
        },
    },
}
</script>
