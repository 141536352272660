<template>
    <div>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <div class="vx-card sm:p-4 p-2">
                    <!-- 資料載入中 -->
                    <h5 v-if="loadingFlag" class="my-2 text-center">{{ $t('loading') }}</h5>
                    <!-- 資料 -->
                    <vs-tabs class="tabs-shadow-none" v-if="!loadingFlag && tabs">
                        <vs-tab class="flex" :label="geti18n(data.i18n)" :id="index" v-for="(data, index) in tabs" :key="index">
                            <!-- 螢幕寬度大於等於768px :subtitle="item.name" -->
                            <vs-list v-if="bioData[index] && windowWidth >= 768">
                                <vs-list-item :title="item.cn + ' ' + item.name" v-for="(item, i) in bioData[index]" :key="i">
                                    <vs-row>
                                        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                                            <p class="cd-date">{{ item.time == '' || item.time == null ? '' : item.time }}</p>
                                            <p class="ml-2" :style="{ color: item.color == 'true' ? 'red' : '' }">{{ item.value == '' || item.value == null ? '-' : item.value }}</p>
                                            <vs-button class="ml-2" icon="show_chart" @click="viewChart(item.type)"></vs-button>
                                        </vs-col>
                                    </vs-row>
                                </vs-list-item>
                            </vs-list>
                            <!-- 螢幕寬度小於768px -->
                            <vs-list v-else-if="bioData[index] && windowWidth < 768">
                                <vs-list-item class="cd-list-item-sm w-full" :title="item.cn" :subtitle="item.name" v-for="(item, i) in bioData[index]" :key="i">
                                    <vs-row>
                                        <vs-col class="mb-2" vs-type="flex" vs-justify="flex-end" vs-align="center">
                                            <p :style="{ color: item.color == 'true' ? 'red' : '' }">{{ item.value == '' || item.value == null ? '-' : item.value }}</p>
                                        </vs-col>
                                        <vs-col vs-type="flex" vs-justify="space-between" vs-align="center">
                                            <p class="cd-date">{{ item.time == '' || item.time == null ? '' : item.time }}</p>
                                            <vs-button icon="show_chart" class="sm:ml-3" @click="viewChart(item.type)"></vs-button>
                                        </vs-col>
                                    </vs-row>
                                </vs-list-item>
                            </vs-list>
                        </vs-tab>
                    </vs-tabs>
                </div>
            </vs-col>
        </vs-row>
        <!-- 圖表 視窗 -->
        <vs-popup :title="$t('Chart')" :active.sync="popupActivChart">
            <vue-apex-charts type="area" height="400vh" ref="lineChart" :options="chart.chartOptions" :series="chart.series"></vue-apex-charts>
        </vs-popup>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        VueApexCharts,
    },
    props: {
        userPid: {
            type: String,
            required: true,
        },
        loadingFlag: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            popupActivChart: false,
            chart: {
                series: [
                    {
                        name: '身高(CM)',
                        data: [],
                    },
                ],
                chartOptions: {
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: 'smooth',
                    },
                    colors: this.themeColors,
                    xaxis: {
                        type: 'datetime',
                        categories: [],
                    },
                    tooltip: {
                        x: {
                            format: 'yyyy-MM-dd',
                        },
                    },
                },
            },
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        },
        bioData() {
            return this.$store.getters.bioData
        },
        tabs() {
            return JSON.parse(localStorage.getItem('his_config')).emr.bioData.contents
        },
    },
    methods: {
        // 開啟 圖表 視窗
        viewChart(type) {
            var _self = this
            _self.chart.series.name = type
            _self.updateChart(type)
        },
        // 產生圖表
        updateChart(type) {
            var _self = this
            _self.viewChartType = type
            var data = {
                pid: _self.userPid,
                type: _self.viewChartType,
                s_time: '2014-01-01',
                e_time: '2020-04-20',
            }
            axios.post(process.env.VUE_APP_API_URL + '/admin/personalreportlist', data).then((res) => {
                if (res.data.status == 'OK') {
                    var data = res.data.list
                    if (data.length < 1) {
                        _self.$vs.dialog({
                            color: 'primary',
                            title: _self.$t('DataNotEnough'),
                            text: _self.$t('CannotDraw'),
                            accept: _self.acceptAlert,
                        })
                    } else {
                        const newData = data.map((item) => {
                            return item.value
                        })
                        _self.chart.series = [
                            {
                                data: newData,
                            },
                        ]
                        _self.chart.series[0].name = type
                        const newOption = data.map((item) => {
                            return item.time
                        })
                        _self.chart.chartOptions = {
                            xaxis: {
                                categories: newOption,
                            },
                        }
                        _self.popupActivChart = true
                    }
                }
            })
        },
        // 判斷語系
        geti18n(items) {
            if (this.$i18n.locale == 'tw') {
                return items.tw
            } else if (this.$i18n.locale == 'cn') {
                return items.cn
            } else if (this.$i18n.locale == 'en') {
                return items.en
            }
        },
    },
}
</script>
